import { Link } from 'gatsby';
import React from 'react';

import { Column, Container, Row } from '../../elements/Grid';
import { Navigation, Paragraph, Wrapper } from './FooterStyle';
import Logo from '../Logo';

const Footer = () => {
  return (
    <Wrapper>
      <Container>
        <Row marginBottom="0">
          <Column width="1/6">
            <Paragraph>
              <a
                href="https://moneywise.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                MoneyWise.com
              </a>
              <br />
              <br />
              <a
                href="https://money.ca/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Money.ca
              </a>
              <br />
              <br />
              <a
                  href="https://moneywise.co.uk/"
                  target="_blank"
                  rel="noopener noreferrer"
              >
                MoneyWise.co.uk
              </a>
            </Paragraph>
          </Column>
          <Column width="1/12" />
          <Column width="1/2">
            <Logo color="#eee" />
            <Navigation>
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/contact/">Contact</Link>
                </li>
                <li>
                  <Link to="/team/">Team</Link>
                </li>
                <li>
                  <Link to="/careers/">Careers</Link>
                </li>
                <li>
                  <Link to="/team-member-of-the-month/">Recognition</Link>
                </li>
                <li>
                  <Link to="/work-with-us/">Work with Us</Link>
                </li>
                <li>
                  <Link to="/news/">News</Link>
                </li>
              </ul>
            </Navigation>
            <Paragraph>© 2024 Wise Publishing, Inc.</Paragraph>
          </Column>
          <Column width="1/12" />
          <Column width="1/6">
            <Paragraph>
              <Link to="/privacy-policy/">Privacy Policy</Link>
              <br />
              <br />
              <Link to="/terms-of-use/">Terms of Use</Link>
              <br />
              <br />
              <Link to="/accessibility-policy/">Accessibility Policy</Link>
            </Paragraph>
          </Column>
        </Row>
      </Container>
    </Wrapper>
  );
};

export default Footer;

import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import Logo from '../Logo';
import { LogoWrapper, Navigation, Wrapper } from './HeaderStyle';

const Header = ({ siteTitle }) => (
  <Wrapper>
    <LogoWrapper>
      <Link to="/">
        <Logo color="#560764" />
      </Link>
    </LogoWrapper>
    <Navigation>
      <ul>
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <Link to="/contact/">Contact</Link>
        </li>
        <li>
          <Link to="/team/">Team</Link>
        </li>
        <li>
          <Link to="/about/">About</Link>
        </li>
        <li>
          <Link to="/careers/">Careers</Link>
        </li>
        <li>
          <Link to="/team-member-of-the-month/">Recognition</Link>
        </li>
        <li>
          <Link to="/advertise/">Advertise with Us</Link>
        </li>
        <li>
          <Link to="/news/">News</Link>
        </li>
      </ul>
    </Navigation>
  </Wrapper>
);

Header.propTypes = {
  siteTitle: PropTypes.string
};

Header.defaultProps = {
  siteTitle: ``
};

export default Header;
